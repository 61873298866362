import $ from 'jquery';

;(() => {

  const $window = $(window);
  const $document = $(document);
  const $footer = $('#footer');

  if (!$footer.length || !$window.length || !$document.length) {
    return;
  }

  const $companiesListCarousel = $footer.find('.companies-list-carousel');
  const $companiesList = $companiesListCarousel.find('.companies-list');
  let $companiesListCarouselItem = $companiesList.find('.companies-list-item');

  if ($companiesListCarousel.length && $companiesListCarouselItem.length) {
    const $carouselNavigationLast = $companiesListCarousel.find('.carousel-navigation-last');
    const $carouselNavigationNext = $companiesListCarousel.find('.carousel-navigation-next');
    let companiesCarouselAmount = $companiesListCarouselItem.length;
    let companiesCarouselPosition = 1;
    let companiesListCarouselInterval = null;
    let companiesListCarouselTime = 3000;
    let companiesListTransitionTime = 100;
    let isMobile = false;

    const carouselIntervalFunction = () => {
      companiesCarouselPosition += 1;
      updateCarousel();
    };

    const transformCompaniesListCarouselItem = (position, transition) => {
      $companiesListCarouselItem.css({
        transform: `translateX(${position * -100}%)`,
        transition: transition ? `transform ${companiesListTransitionTime}ms` : 'none',
      });
    };

    const onBoundaryTransitionEnd = (position) => {
      $companiesListCarouselItem.off('transitionend webkitTransitionEnd oTransitionEnd');
      companiesCarouselPosition = position;
      transformCompaniesListCarouselItem(companiesCarouselPosition, false);
    };

    const updateCarousel = () => {
      if (companiesCarouselPosition <= 0) {
        $companiesListCarouselItem.on(
          'transitionend webkitTransitionEnd oTransitionEnd',
          () => onBoundaryTransitionEnd(companiesCarouselAmount - 2),
        );
      } else if (companiesCarouselPosition >= companiesCarouselAmount - 1) {
        $companiesListCarouselItem.on(
          'transitionend webkitTransitionEnd oTransitionEnd',
          () => onBoundaryTransitionEnd(1),
        );
      }

      transformCompaniesListCarouselItem(companiesCarouselPosition, true);
    };

    const clearCarouselInterval = () => {
      if (companiesListCarouselInterval) {
        clearInterval(companiesListCarouselInterval);
      }
    };

    const resetCarouselInterval = () => {
      clearCarouselInterval();
      companiesListCarouselInterval = setInterval(
        carouselIntervalFunction,
        companiesListCarouselTime,
      );
    };

    const onCarouselNavigation = (position, $target) => {
      resetCarouselInterval();

      if ($target && $target.length) {
        $target.attr('disabled', 'disabled');
        setTimeout(() => $target.removeAttr('disabled'), companiesListTransitionTime);
      }

      companiesCarouselPosition = position;
      updateCarousel();
    };

    const setupCompaniesListCarousel = () => {
      if (companiesCarouselAmount > 1) {
        const $firstCompaniesListCarouselItemClone = $companiesListCarouselItem.eq(0).clone();
        const $lastCompaniesListCarouselItemClone = $companiesListCarouselItem.eq(
          companiesCarouselAmount - 1,
        ).clone();
        const carouselTimeAttr = $companiesListCarousel.attr('carousel-time');
        const transitionTimeAttr = $companiesListCarousel.attr('transition-time');

        if (carouselTimeAttr && carouselTimeAttr.length) {
          companiesListCarouselTime = parseInt(carouselTimeAttr, 10);
        }

        if (transitionTimeAttr && transitionTimeAttr.length) {
          companiesListTransitionTime = parseInt(transitionTimeAttr, 10);
        }

        $firstCompaniesListCarouselItemClone.addClass('item-clone').appendTo($companiesList);
        $lastCompaniesListCarouselItemClone.addClass('item-clone').prependTo($companiesList);
        $companiesListCarouselItem = $companiesList.find('.companies-list-item');
        companiesCarouselAmount = $companiesListCarouselItem.length;
        $carouselNavigationLast.click(() => onCarouselNavigation(
          companiesCarouselPosition - 1,
          $carouselNavigationLast,
        ));
        $carouselNavigationNext.click(() => onCarouselNavigation(
          companiesCarouselPosition + 1,
          $carouselNavigationNext,
        ));
        updateCarousel();
      }
    };

    const onWindowResize = () => {
      const windowWidth = $window.width();

      if (!isMobile && windowWidth < 768) {
        isMobile = true;
        resetCarouselInterval();
      } else if (isMobile && windowWidth >= 768) {
        isMobile = false;
        clearCarouselInterval();
      }
    };

    const onDocumentReady = () => {
      setupCompaniesListCarousel();
      onWindowResize();
      $window.resize(onWindowResize);
    };

    $document.ready(onDocumentReady);
  }

})();
