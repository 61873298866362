import $ from 'jquery';

;(() => {

  const $window = $(window);
  const $document = $(document);
  const $navigationHeader = $('#navigation-header');
  let lastWindowWidth = 0;

  if (!$navigationHeader.length || !$window.length || !$document.length) {
    return;
  }

  const $activateMenu = $navigationHeader.find('.activate-menu');

  if ($activateMenu.length) {
    $activateMenu.on('click', () => {
      $navigationHeader.toggleClass('activated-menu');
    });
  }

  const onWindowResize = () => {
    const windowWidth = $window.width();

    if (windowWidth < 1024 && lastWindowWidth >= 1024) {
      $navigationHeader.addClass('no-transition');
      setTimeout(() => $navigationHeader.removeClass('no-transition'), 1000);
    }

    lastWindowWidth = windowWidth;
  };

  const onDocumentReady = () => {
    onWindowResize();
    $window.resize(onWindowResize);
  };

  $document.ready(onDocumentReady);

})();
