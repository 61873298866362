import './js/pages/archive-happening';
import './js/pages/landing';
import './js/pages/menus';
import './js/pages/visit-us';

import './js/components/event-alert-list';
import './js/components/footer';
import './js/components/menus-list';
import './js/components/navigation-header';
import './js/components/whats-happening';

import './scss/style.scss';
