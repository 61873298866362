import $ from 'jquery';

;(() => {

  const $document = $(document);
  const $pageTemplateArchiveHappening = $('.post-type-archive-happening');

  if (!$pageTemplateArchiveHappening.length || !$document.length) {
    return;
  }

  const $happeningsHighlightsCarousel = $pageTemplateArchiveHappening.find(
    '.happenings-highlights-carousel',
  );
  const hasHappeningsHighlightsCarousel = $happeningsHighlightsCarousel &&
    $happeningsHighlightsCarousel.length;
  let setupHappeningsHighlightsCarousel = null;

  if (hasHappeningsHighlightsCarousel) {
    const $highlightsCarouselItem = $happeningsHighlightsCarousel.find(
      '.highlights-carousel-item',
    );
    const carouselTimeAttr = $happeningsHighlightsCarousel.attr('carousel-time');
    const transitionTimeAttr = $happeningsHighlightsCarousel.attr('transition-time');
    const $carouselNavigationLast = $happeningsHighlightsCarousel.find(
      '.carousel-navigation-last',
    );
    const $carouselNavigationNext = $happeningsHighlightsCarousel.find(
      '.carousel-navigation-next',
    );
    let imageCarouselTime = 1000;
    let imageTransitionTime = 100;
    let carouselInterval = null;
    let carouselPosition = 0;
    let carouselItemAmount = $highlightsCarouselItem.length;

    const updateCarousel = () => {
      if (carouselPosition > carouselItemAmount - 1) {
        carouselPosition = 0;
      } else if (carouselPosition < 0) {
        carouselPosition = carouselItemAmount - 1;
      }

      $highlightsCarouselItem.removeClass('active');
      $highlightsCarouselItem.eq(carouselPosition).addClass('active');
    };

    const carouselIntervalFunction = () => {
      carouselPosition += 1;
      updateCarousel();
    };

    const resetCarouselInterval = () => {
      if (carouselInterval) {
        clearInterval(carouselInterval);
      }

      carouselInterval = setInterval(carouselIntervalFunction, imageCarouselTime);
    };

    setupHappeningsHighlightsCarousel = () => {
      if (carouselTimeAttr && carouselTimeAttr.length) {
        imageCarouselTime = parseInt(carouselTimeAttr, 10);
      }

      if (transitionTimeAttr && transitionTimeAttr.length) {
        imageTransitionTime = parseInt(transitionTimeAttr, 10);
      }

      $highlightsCarouselItem.css({ transition: `opacity ${imageTransitionTime}ms`});

      if ($highlightsCarouselItem && $highlightsCarouselItem.length) {
        carouselItemAmount = $highlightsCarouselItem.length;
        $highlightsCarouselItem.eq(0).addClass('active');

        if (carouselItemAmount > 1) {
          resetCarouselInterval();
        }
      }

      if ($carouselNavigationLast && $carouselNavigationLast.length) {
        $carouselNavigationLast.click(() => {
          carouselPosition -= 1;
          updateCarousel();
          resetCarouselInterval();
        });
      }

      if ($carouselNavigationNext && $carouselNavigationNext.length) {
        $carouselNavigationNext.click(() => {
          carouselPosition += 1;
          updateCarousel();
          resetCarouselInterval();
        });
      }
    };
  }

  const onDocumentReady = () => {
    setupHappeningsHighlightsCarousel();
  };

  $document.ready(onDocumentReady);

})();
