import $ from 'jquery';

;(() => {

  const $window = $(window);
  const $document = $(document);
  const $whatsHappening = $('.whats-happening');

  if (!$whatsHappening.length || !$window.length || !$document.length) {
    return;
  }

  const $whatsHappeningCarousel = $whatsHappening.find('.whats-happening-carousel');
  const $whatsHappeningList = $whatsHappeningCarousel.find('.whats-happening-list');
  let $whatsHappeningItem = $whatsHappeningList.find('.whats-happening-item');
  let setupWhatsHappeningCarousel = null;

  if (
    $whatsHappeningList &&
    $whatsHappeningList.length &&
    $whatsHappeningItem &&
    $whatsHappeningItem.length
  ) {
    const $carouselNavigationLast = $whatsHappeningCarousel.find('.carousel-navigation-last');
    const $carouselNavigationNext = $whatsHappeningCarousel.find('.carousel-navigation-next');
    const whatsHappeningRealAmount = $whatsHappeningItem.length;
    const $whatsHappeningCarouselNavigationList = $whatsHappening.find(
      '.whats-happening-carousel-navigation-list',
    );
    const $carouselNavigationItem = $whatsHappeningCarouselNavigationList.find(
      '.carousel-navigation-item',
    );
    let whatsHappeningAmount = whatsHappeningRealAmount;
    let whatsHappeningPosition = 0;
    let whatsHappeningInterval = null;
    let whatsHappeningTime = 3000;
    let whatsHappeningTransitionTime = 100;

    const carouselIntervalFunction = () => {
      whatsHappeningPosition += 1;
      updateCarousel();
    };

    const transformCarouselItem = (position, transition) => {
      $whatsHappeningItem.css({
        transform: `translateX(${position * -100}%)`,
        transition: transition ? `transform ${whatsHappeningTransitionTime}ms` : 'none',
      });
    };

    const onBoundaryTransitionEnd = (position) => {
      $whatsHappeningItem.off('transitionend webkitTransitionEnd oTransitionEnd');
      whatsHappeningPosition = position;
      transformCarouselItem(whatsHappeningPosition, false);
    };

    const updateCarousel = () => {
      if (whatsHappeningPosition <= 2) {
        $whatsHappeningItem.on(
          'transitionend webkitTransitionEnd oTransitionEnd',
          () => onBoundaryTransitionEnd(whatsHappeningAmount - 4),
        );
      }

      if (whatsHappeningPosition >= whatsHappeningAmount - 3) {
        $whatsHappeningItem.on(
          'transitionend webkitTransitionEnd oTransitionEnd',
          () => onBoundaryTransitionEnd(3),
        );
      }

      transformCarouselItem(whatsHappeningPosition, true);

      let realPosition =  whatsHappeningPosition - 2;

      if (realPosition === whatsHappeningRealAmount + 1) {
        realPosition = 1;
      } else if (realPosition < 0 || realPosition >= whatsHappeningRealAmount) {
        realPosition = 0;
      }

      $carouselNavigationItem.removeClass('active');
      $carouselNavigationItem.eq(realPosition).addClass('active');
    };

    const clearCarouselInterval = () => {
      if (whatsHappeningInterval) {
        clearInterval(whatsHappeningInterval);
      }
    };

    const resetCarouselInterval = () => {
      clearCarouselInterval();
      whatsHappeningInterval = setInterval(carouselIntervalFunction, whatsHappeningTime);
    };

    const onCarouselNavigation = (position, $target) => {
      resetCarouselInterval();

      if ($target && $target.length) {
        $target.attr('disabled', 'disabled');
        setTimeout(() => $target.removeAttr('disabled'), whatsHappeningTransitionTime);
      }

      whatsHappeningPosition = position;
      updateCarousel();
    };

    setupWhatsHappeningCarousel = () => {
      if (whatsHappeningAmount > 1) {
        let append = [];
        let prepend = [];

        if (whatsHappeningAmount >= 3) {
          append = [
            $whatsHappeningItem.eq(0).clone().addClass('item-clone'),
            $whatsHappeningItem.eq(1).clone().addClass('item-clone'),
            $whatsHappeningItem.eq(2).clone().addClass('item-clone'),
          ];
          prepend = [
            $whatsHappeningItem.eq(whatsHappeningAmount - 3).clone().addClass('item-clone'),
            $whatsHappeningItem.eq(whatsHappeningAmount - 2).clone().addClass('item-clone'),
            $whatsHappeningItem.eq(whatsHappeningAmount - 1).clone().addClass('item-clone'),
          ];
        } else {
          append = [
            $whatsHappeningItem.eq(0).clone().addClass('item-clone'),
            $whatsHappeningItem.eq(1).clone().addClass('item-clone'),
            $whatsHappeningItem.eq(0).clone().addClass('item-clone'),
          ];
          prepend = [
            $whatsHappeningItem.eq(whatsHappeningAmount - 1).clone().addClass('item-clone'),
            $whatsHappeningItem.eq(whatsHappeningAmount - 2).clone().addClass('item-clone'),
            $whatsHappeningItem.eq(whatsHappeningAmount - 1).clone().addClass('item-clone'),
          ];
        }

        $whatsHappeningList.append(append);
        $whatsHappeningList.prepend(prepend);

        const carouselTimeAttr = $whatsHappeningCarousel.attr('carousel-time');
        const transitionTimeAttr = $whatsHappeningCarousel.attr('transition-time');

        if (carouselTimeAttr && carouselTimeAttr.length) {
          whatsHappeningTime = parseInt(carouselTimeAttr, 10);
        }

        if (transitionTimeAttr && transitionTimeAttr.length) {
          whatsHappeningTransitionTime = parseInt(transitionTimeAttr, 10);
        }

        $whatsHappeningItem = $whatsHappeningList.find('.whats-happening-item');
        whatsHappeningAmount = $whatsHappeningItem.length;
        $carouselNavigationLast.click(() => onCarouselNavigation(
          whatsHappeningPosition - 1,
          $carouselNavigationLast,
        ));
        $carouselNavigationNext.click(() => onCarouselNavigation(
          whatsHappeningPosition + 1,
          $carouselNavigationNext,
        ));

        const $navigationItemButton = $carouselNavigationItem.find('.navigation-item-button')

        if ($navigationItemButton && $navigationItemButton.length) {
          $navigationItemButton.click((event) => {
            let value = $(event.target).attr('position');

            if (value && value.length) {
              value = parseInt(value, 10);
            }

            whatsHappeningPosition = value + 2;
            resetCarouselInterval();
            updateCarousel();
          });
        }

        resetCarouselInterval();
        updateCarousel();
      }
    };
  }

  const onDocumentReady = () => {
    if (setupWhatsHappeningCarousel) {
      setupWhatsHappeningCarousel();
    }
  };

  $document.ready(onDocumentReady);

})();
