import $ from 'jquery';

;(() => {

  const $document = $(document);
  const $pageTemplateVisitUs = $('.page-template-visit-us');

  if (!$pageTemplateVisitUs.length || !$document.length) {
    return;
  }

  const $visitUsCoverCarousel = $pageTemplateVisitUs.find('.visit-us-cover-carousel');
  const hasVisitUsCoverCarousel = $visitUsCoverCarousel && $visitUsCoverCarousel.length;
  let setupVisitUsCoverCarousel = null;

  if (hasVisitUsCoverCarousel) {
    const $coverCarouselItem = $visitUsCoverCarousel.find('.cover-carousel-item');
    const carouselTimeAttr = $visitUsCoverCarousel.attr('carousel-time');
    const transitionTimeAttr = $visitUsCoverCarousel.attr('transition-time');
    const $carouselNavigationLast = $visitUsCoverCarousel.find('.carousel-navigation-last');
    const $carouselNavigationNext = $visitUsCoverCarousel.find('.carousel-navigation-next');
    let imageCarouselTime = 1000;
    let imageTransitionTime = 100;
    let carouselInterval = null;
    let menusCoverImageCarouselPosition = 0;
    let imageCarouselItemAmount = $coverCarouselItem.length;

    const updateCarousel = () => {
      if (menusCoverImageCarouselPosition > imageCarouselItemAmount - 1) {
        menusCoverImageCarouselPosition = 0;
      } else if (menusCoverImageCarouselPosition < 0) {
        menusCoverImageCarouselPosition = imageCarouselItemAmount - 1;
      }

      $coverCarouselItem.removeClass('active');
      $coverCarouselItem.eq(menusCoverImageCarouselPosition).addClass('active');
    };

    const carouselIntervalFunction = () => {
      menusCoverImageCarouselPosition += 1;
      updateCarousel();
    };

    const resetCarouselInterval = () => {
      if (carouselInterval) {
        clearInterval(carouselInterval);
      }

      carouselInterval = setInterval(carouselIntervalFunction, imageCarouselTime);
    };

    setupVisitUsCoverCarousel = () => {
      if (carouselTimeAttr && carouselTimeAttr.length) {
        imageCarouselTime = parseInt(carouselTimeAttr, 10);
      }

      if (transitionTimeAttr && transitionTimeAttr.length) {
        imageTransitionTime = parseInt(transitionTimeAttr, 10);
      }

      $coverCarouselItem.css({ transition: `opacity ${imageTransitionTime}ms`});

      if ($coverCarouselItem && $coverCarouselItem.length) {
        imageCarouselItemAmount = $coverCarouselItem.length;
        $coverCarouselItem.eq(0).addClass('active');

        if (imageCarouselItemAmount > 1) {
          resetCarouselInterval();
        }
      }

      if ($carouselNavigationLast && $carouselNavigationLast.length) {
        $carouselNavigationLast.click(() => {
          menusCoverImageCarouselPosition -= 1;
          updateCarousel();
          resetCarouselInterval();
        });
      }

      if ($carouselNavigationNext && $carouselNavigationNext.length) {
        $carouselNavigationNext.click(() => {
          menusCoverImageCarouselPosition += 1;
          updateCarousel();
          resetCarouselInterval();
        });
      }
    };
  }

  const onDocumentReady = () => {
    if (setupVisitUsCoverCarousel) {
      setupVisitUsCoverCarousel();
    }
  };

  $document.ready(onDocumentReady);

})();
