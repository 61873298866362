import $ from 'jquery';

;(() => {

  const $window = $(window);
  const $document = $(document);
  const $pageTemplateMenus = $('.page-template-menus');

  if (!$pageTemplateMenus.length || !$window.length || !$document.length) {
    return;
  }

  const urlParams = new URLSearchParams(window.location.search);
  const urlParamsGo = urlParams.get('go');
  const $menusCoverImageCarousel = $pageTemplateMenus.find('.menus-cover-image-carousel');
  const hasMenusCoverImageCarousel = $menusCoverImageCarousel && $menusCoverImageCarousel.length;
  let windowWidth = $window.width();

  const setupUrlParamsGo = () => {
    if (urlParamsGo && urlParamsGo.length) {
      const $elementToGo = $(`#${urlParamsGo}`);

      if ($elementToGo.length) {
        $elementToGo.addClass('show-content');
        $([document.documentElement, document.body]).animate({
          scrollTop: $elementToGo.offset().top - (windowWidth >= 1024 ? 80 : 65)
        }, 400);
      }
    }
  };

  const setupMenusCoverImageCarousel = () => {
    if (hasMenusCoverImageCarousel) {
      const $imageCarouselItem = $menusCoverImageCarousel.find('.image-carousel-item');
      const carouselTimeAttr = $menusCoverImageCarousel.attr('carousel-time');
      const transitionTimeAttr = $menusCoverImageCarousel.attr('transition-time');
      let imageCarouselTime = 1000;
      let imageTransitionTime = 100;

      if (carouselTimeAttr && carouselTimeAttr.length) {
        imageCarouselTime = parseInt(carouselTimeAttr, 10);
      }

      if (transitionTimeAttr && transitionTimeAttr.length) {
        imageTransitionTime = parseInt(transitionTimeAttr, 10);
        $imageCarouselItem.css({ transition: `opacity ${imageTransitionTime}ms`})
      }

      if ($imageCarouselItem && $imageCarouselItem.length) {
        const imageCarouselItemAmount = $imageCarouselItem.length;

        $imageCarouselItem.eq(0).addClass('active');

        if (imageCarouselItemAmount > 1) {
          let menusCoverImageCarouselPosition = 0;

          setInterval(() => {
            menusCoverImageCarouselPosition += 1;

            if (menusCoverImageCarouselPosition > imageCarouselItemAmount - 1) {
              menusCoverImageCarouselPosition = 0;
            }

            $imageCarouselItem.removeClass('active');
            $imageCarouselItem.eq(menusCoverImageCarouselPosition).addClass('active');
          }, imageCarouselTime);
        }
      }
    }
  };

  const onDocumentReady = () => {
    setupUrlParamsGo();
    setupMenusCoverImageCarousel();
  };

  const onWindowResize = () => {
    windowWidth = $window.width();
  };

  $document.ready(onDocumentReady);
  $window.resize(onWindowResize);

})();
