import $ from 'jquery';

;(() => {

  const $menusList = $('.menus-list');

  if (!$menusList.length || $menusList.hasClass('menus-list-anchor')) {
    return;
  }

  const $menuItemList = $menusList.find('.menu-item');

  if ($menuItemList.length) {
    $menuItemList.each((index) => {
      const $currentMenuItem = $menuItemList.eq(index);
      const $closeMenuItem = $currentMenuItem.find('.close-menu-item');
      const $openMenuItem = $currentMenuItem.find('.open-menu-item');

      if ($closeMenuItem.length) {
        $closeMenuItem.on('click', () => {
          $currentMenuItem.removeClass('show-content');
        });
      }

      if ($openMenuItem.length) {
        $openMenuItem.on('click', () => {
          $currentMenuItem.toggleClass('show-content');
        });
      }
    });
  }

})();
