import $ from 'jquery';

;(() => {

  const $window = $(window);
  const $document = $(document);
  const $landingPage = $('.page-template-landing');

  if (!$landingPage.length || !$window.length || !$document.length) {
    return;
  }

  const $landingCoverCarouselImage = $landingPage.find('.landing-cover-carousel-image');
  let setupLandingCoverCarouselImage = null;

  if ($landingCoverCarouselImage && $landingCoverCarouselImage.length) {
    setupLandingCoverCarouselImage = () => {
      const $carouselImageItem = $landingCoverCarouselImage.find('.carousel-image-item');
      const carouselTimeAttr = $landingCoverCarouselImage.attr('carousel-time');
      const transitionTimeAttr = $landingCoverCarouselImage.attr('transition-time');
      let imageCarouselTime = 1000;
      let imageTransitionTime = 100;

      if (carouselTimeAttr && carouselTimeAttr.length) {
        imageCarouselTime = parseInt(carouselTimeAttr, 10);
      }

      if (transitionTimeAttr && transitionTimeAttr.length) {
        imageTransitionTime = parseInt(transitionTimeAttr, 10);
        $carouselImageItem.css({ transition: `opacity ${imageTransitionTime}ms`})
      }

      if ($carouselImageItem && $carouselImageItem.length) {
        const carouselImageItemAmount = $carouselImageItem.length;

        $carouselImageItem.eq(0).addClass('active');

        if (carouselImageItemAmount > 1) {
          let imagePosition = 0;

          setInterval(() => {
            imagePosition += 1;

            if (imagePosition > carouselImageItemAmount - 1) {
              imagePosition = 0;
            }

            $carouselImageItem.removeClass('active');
            $carouselImageItem.eq(imagePosition).addClass('active');
          }, imageCarouselTime);
        }
      }
    };
  }

  const onDocumentReady = () => {
    if (setupLandingCoverCarouselImage) {
      setupLandingCoverCarouselImage();
    }
  };

  $document.ready(onDocumentReady);

})();
