import $ from 'jquery';

;(() => {

  const $window = $(window);
  const $document = $(document);
  const $eventAlertList = $('#event-alert-list');
  const $mainContainer = $('#main-container');
  const $navigationHeader = $('#navigation-header');

  if (
    !$eventAlertList.length ||
    !$mainContainer.length ||
    !$navigationHeader.length ||
    !$window.length ||
    !$document.length
  ) {
    return;
  }

  const $eventAlertItem = $eventAlertList.find('.event-alert-item');

  const setupEventAlertList = () => {
    if ($eventAlertItem.length) {
      const $eventAlertContanier = $('<div class="event-alert-container hide" />');

      $eventAlertItem.each((index) => {
        const $current = $eventAlertItem.eq(index);

        if ($current.length) {
          const content = $current.attr('event-name');

          if (content && content.length) {
            const $eventAlertItem = $(`<div class="event-alert-item">${content}</div>`);
            const $closeEventAlertButton = $('<button class="close-envent-alert"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><polygon points="11.35 1.35 10.65 0.65 6 5.29 1.35 0.65 0.65 1.35 5.29 6 0.65 10.65 1.35 11.35 6 6.71 10.65 11.35 11.35 10.65 6.71 6 11.35 1.35" /></svg></button>');

            const onClickClose = () => {
              $eventAlertContanier.addClass('hide');
              $mainContainer.removeClass('with-alert');
              $navigationHeader.removeClass('with-alert');
            };

            $closeEventAlertButton.click(onClickClose);
            $eventAlertItem.append($closeEventAlertButton);
            $eventAlertContanier.append($eventAlertItem);
          }
        }
      });

      $mainContainer.prepend($eventAlertContanier);
      setTimeout(() => {
        $eventAlertContanier.removeClass('hide');
        $mainContainer.addClass('with-alert');
        $navigationHeader.addClass('with-alert');
      }, 100);
    }
  };

  const onDocumentReady = () => {
    setupEventAlertList();
  };

  $document.ready(onDocumentReady);

})();
